<template>
  <card class="p-3 col-lg-8 bg-secondary">
    <h2 class="page-header mb-lg-3 card-title">Enter your work email address</h2>
    <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
      <form class="needs-validation" @submit.prevent="validate().then(submit)">
        <div class="row d-flex justify-content-center">
          <div class="mx-3 w-100">
            <b-row class="mb-4">
              <b-col lg="12">
                <base-input
                    v-model="email"
                    alternative
                    group
                    name="email"
                    placeholder="Work email address"
                    rules="required|email"
                    type="email"
                    formGroupClasses="mb-0"
                ></base-input>
              </b-col>
            </b-row>
            <div class="layout-button">
              <button
                :disabled="invalid"
                class="btn btn-outline btn-primary btn-block font-weight-bold"
                type="submit"
              >Sign in
              </button>
            </div>
            <div class="mt-2rem text-center">
              <p class="font-weight-bold">
                <router-link :to="{ name: 'Login' }">Return to Sign in</router-link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>
<script>
import {FETCH_SSO_URL_REQUEST} from '@/store/storeActions';
import {mapActions} from 'vuex';

export default {
  data() {
    return {
      email: '',
      validator: null,
    };
  },
  methods: {
    ...mapActions([
      FETCH_SSO_URL_REQUEST,
    ]),
    // Make an API call to retrieve the SSO URL based on the email
    async submit() {
      const email = this.email;
      const ssoUrl = await this.FETCH_SSO_URL_REQUEST(email);
      if (ssoUrl) {
        window.location.href = ssoUrl; // Redirect to the url
        this.clearData();
      }
    },
    // Function to clear form data after submission
    clearData() {
      this.email = '';
      this.validator.reset();
    },
  },
  // Assign the form validator reference to the 'validator' variable
  mounted: function() {
    this.validator = this.$refs.formValidator;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/lbd/mixins/_layout.scss";
@import "@/assets/sass/custom/_app-vars.scss";
</style>
